import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-twelve',
  templateUrl: './work-twelve.component.html',
  styleUrls: ['./work-twelve.component.css']
})
export class WorkTwelveComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/seylan-web-and-tikiri/1.jpg', 'caption': 'Seylan Web Banners Campaign', 'thumb': 'assets/img/work/seylan-web-and-tikiri/thumbs/work-1.jpg',
      'type': 'image', 'link': 'works/seylan-bank-6', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-web-and-tikiri/2.jpg', 'caption': 'Seylan Web Banners Campaign', 'thumb': 'assets/img/work/seylan-web-and-tikiri/thumbs/work-2.jpg',
      'type': 'image', 'link': 'works/seylan-bank-6', "index": 2
    });
    
    this.album.push({
      'src': 'assets/img/work/seylan-web-and-tikiri/3.jpg', 'caption': 'Seylan Tikiri Panel Campaign', 'thumb': 'assets/img/work/seylan-web-and-tikiri/thumbs/work-3.jpg',
      'type': 'image', 'link': 'works/seylan-bank-6', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/seylan-web-and-tikiri/4.jpg', 'caption': 'Seylan Tikiri Panel Campaign', 'thumb': 'assets/img/work/seylan-web-and-tikiri/thumbs/work-4.jpg',
      'type': 'image', 'link': 'works/seylan-bank-6', "index": 4
    });

    

    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
