import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-nine',
  templateUrl: './work-nine.component.html',
  styleUrls: ['./work-nine.component.css']
})
export class WorkNineComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig ) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/13.jpg', 'caption': 'Heroes of the Heart Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-13.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=su-s2C7P0rM', "index": 1
    });


    this.album.push({
      'src': 'assets/img/work/seylan-hero/2.jpg', 'caption': 'Heroes of the Heart Campaign', 'thumb': 'assets/img/work/seylan-hero/thumbs/work-2.jpg',
      'type': 'image', 'link': '/works/seylan-bank-4', "index": 2
    });

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }
}
