import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-six',
  templateUrl: './work-six.component.html',
  styleUrls: ['./work-six.component.css']
})
export class WorkSixComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig ) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/11.jpg', 'caption': 'Spa Ceylon Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-11.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=HMFHdcbUgnw', "index": 1
    });

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
