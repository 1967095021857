import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-fourteen',
  templateUrl: './work-fourteen.component.html',
  styleUrls: ['./work-fourteen.component.css']
})
export class WorkFourteenComponent implements OnInit {
  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/seylan-pdf/1.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-1.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/E-Newsletter_English.pdf', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/2.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-2.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/E-Newsletter_Sinhala.pdf', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/3.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-3.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/E-Newsletter_Tamil.pdf', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/4.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-4.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-T.pdf', "index": 4
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/5.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-5.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-S.pdf', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/6.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-6.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-E.pdf', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/7.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-7.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-Dec-E.pdf', "index": 7
    });


    this.album.push({
      'src': 'assets/img/work/seylan-pdf/8.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-8.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-Dec-S.pdf', "index": 8
    });

    this.album.push({
      'src': 'assets/img/work/seylan-pdf/9.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/seylan-pdf/thumbs/work-9.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-pdf/e-Newsletter-Dec-T.pdf', "index": 9
    });

    

    // //ascending
    // this.album = this.album.sort((a, b) => a.index - b.index);

    // descending
    this.album = this.album.sort((a, b) => b.index - a.index);
    

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
