import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-eight',
  templateUrl: './work-eight.component.html',
  styleUrls: ['./work-eight.component.css']
})
export class WorkEightComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/3.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-3.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/2.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-2.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/3.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-3.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/4.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-4.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 4
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/5.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-5.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/6.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-6.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/7.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-7.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 7
    });


    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/8.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-8.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 8
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/9.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-9.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 9
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/10.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-10.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 10
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/11.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-11.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 11
    });


    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/12.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-12.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 12
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/13.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-13.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 13
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/14.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-14.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 14
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/15.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-15.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 15
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/16.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-16.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 16
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/17.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-17.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 17
    });

    this.album.push({
      'src': 'assets/img/work/seylan-ran-naya/18.jpg', 'caption': 'Selan Bank Ran Naya Campaign', 'thumb': 'assets/img/work/seylan-ran-naya/thumbs/work-18.jpg',
      'type': 'image', 'link': '/works/seylan-bank-3', "index": 18
    });




    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }
}
