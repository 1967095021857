<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Bank Quarterly E News Letter</h2>
                    <p>We have written and design a series of E-newsletters for Seylan Bank with a special focus on
                        Micro, Small and Medium Enterprises, providing them with necessary business guidance. The
                        E-newsletters carry interviews with the Bank’s Top Management and CEO’s of leading organizations
                        in Sri Lanka offering their experiences and knowledge on Management, Strategic Planning, Digital
                        Marketing and other areas of interest. The magazine was designed and the articles solely written
                        by our agency.
                    </p>
                    <!-- <p>
                        When working together with Seylan bank, Designs Dee had to consider many vital factors so as to
                        provide exactly what the bank’s management wanted. The colors, fonts, and other creative
                        parameters were selected adhering to the bank’s default theme. As a result, we were able to
                        protect and strengthen the bank's brand image further as demanded by the bank. We also made
                        every effort to provide the finest possible client-oriented service targeting the bank's various
                        business components, just as we did in the creative design process.
                    </p> -->
                    <span class="section-title-bg">SEYLANBANK</span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">

                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->