import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-one',
  templateUrl: './work-one.component.html',
  styleUrls: ['./work-one.component.css']
})
export class WorkOneComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;

    // this.album.push({ 'src': 'assets/img/work/main-page/6.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-6.jpg' });//1
    // this.album.push({ 'src': 'assets/img/work/seylan-avurudu/2.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-2.jpg' });//2
    // this.album.push({ 'src': 'assets/img/work/seylan-avurudu/3.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-3.jpg' });//3
    // this.album.push({ 'src': 'assets/img/work/seylan-avurudu/4.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-4.jpg' });//4
    // this.album.push({ 'src': 'assets/img/work/seylan-avurudu/5.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-5.jpg' });//5


    this.album.push({
      'src': 'assets/img/work/main-page/6.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-6.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/2.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-2.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/3.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-3.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/4.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-4.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 4
    })

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/5.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-5.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/6.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-6.jpg',
      'type': 'video', 'link': 'assets/img/work/seylan-avurudu/diary.pdf', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/seylan-avurudu/7.jpg', 'caption': 'Seylan Bank', 'thumb': 'assets/img/work/seylan-avurudu/thumbs/work-7.jpg',
      'type': 'image', 'link': '/works/seylan-bank', "index": 7
    });



    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }


}
