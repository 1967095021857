<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Bank Ran Naya Campaign</h2>
                    <p>Seylan Bank promoted its Ran Naya Gold Loan scheme. The relaunch of the logo was designed by us,
                        by winning a pitch against other ad agencies. We were also selected to handle their Gold Loan
                        EDM campaign which covered an Internal Staff Motivational Campaign, an Internal ‘Winners’
                        campaign, as well as Outdoor branding and animated TV commercials.
                    </p>
                    <span class="section-title-bg">SeylanBank</span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>

                <!-- <div class="work-details popup-youtube" href="https://www.youtube.com/watch?v=2oJmusYKi48"  *ngIf="!(image.type == 'image')"> -->
                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">

                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->