import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-eleven',
  templateUrl: './work-eleven.component.html',
  styleUrls: ['./work-eleven.component.css']
})
export class WorkElevenComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/1.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-1.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/2.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-2.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/3.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-3.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/4.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-4.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 4
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/5.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-5.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/6.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-6.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/7.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-7.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 7
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/8.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-8.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 8
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/9.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-9.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 9
    });

    this.album.push({
      'src': 'assets/img/work/seylan-mega-rewards/10.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/seylan-mega-rewards/thumbs/work-10.jpg',
      'type': 'image', 'link': 'works/seylan-bank-5', "index": 10
    });

    //Export from Main Page
    this.album.push({
      'src': 'assets/img/work/main-page/14.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-14.jpg',
      'type': 'image', 'link': '/works/seylan-bank-4', 'class': 'branding', "index": 11
    });



    

    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }


}
