<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Spinner Campaign</h2>
                    <p>Spinner is a one-of-a-kind bicycle repairing, servicing and renting outlet which organizes
                        cycling programs and special cycling events. We are responsible for their entire social media
                        presence. Our campaign utilized creative graphics and animated videos; social media coverage for
                        live events; Air Force Papadi Sawariya, Kimbula race event, launch of “Sunday Cycling” and
                        subsequent “Sunday Cycling” events.
                    </p>
                    <span class="section-title-bg">Spinner</span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>

                <!-- <div class="work-details popup-youtube" href="https://www.youtube.com/watch?v=2oJmusYKi48"  *ngIf="!(image.type == 'image')"> -->
                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">

                    <!-- <img [src]="image.thumb" [alt]="image.caption"> -->


                    <div class="videos">
                        <a href="#" class="video">
                          <span></span>
                          <img [src]="image.thumb" [alt]="image.caption">
                        </a>
                      </div>

                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->