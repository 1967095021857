<!-- Start video area -->
<div class="home-video-area">
    <video loop muted autoplay poster="#" class="video-background">
        <source src="assets/img/promo-video.mp4" type="video/mp4">
    </video>

    <div class="video-text-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-7" data-parallax='{"y" : -300, "smoothness": 20}'>
                    <span class="hero-text">Clean & Creative</span>
                    <h1>Fully Responsive Design</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <div class="center-wrap">
                        <div class="button">
                            <a href="#">get started <i class="fa fa-long-arrow-right"></i></a>
                            <div class="mask"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End video area -->

<!-- Start Feature Area -->
<section class="feature-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="single-feature">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-047-laptop"></i>
                    </div>
                    <h3>Beautiful Designs</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="single-feature">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-017-package"></i>
                    </div>
                    <h3>Unique Features</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="single-feature">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-025-chat"></i>
                    </div>
                    <h3>Reliable Support</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feature Area -->

<!-- Start Services Area -->
<section id="services" class="services ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Services</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-012-management"></i>
                    </div>
                    <h3>Creative Solutions</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-032-target"></i>
                    </div>
                    <h3>Excellent Features</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-024-user"></i>
                    </div>
                    <h3>Friendly Support</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-008-bar-chart"></i>
                    </div>
                    <h3>SEO & Advertising</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-033-networking"></i>
                    </div>
                    <h3>Marketing & Consulting</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-031-laptop-1"></i>
                    </div>
                    <h3>Design & Development</h3>
                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start CTA Area -->
<section class="cta-area cta-bg-img">
    <div class="diplay-table">
        <div class="display-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-7 col-lg-7">
                        <div class="cta-info">
                            <h2>We offer best in class service for your needs</h2>
                            <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>

                            <div class="center-wrap">
                                <div class="button">
                                    <a href="#">Purchage Now <i class="fas fa-long-arrow-alt-right"></i></a>
                                    <div class="mask"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-5 col-lg-5 cta-img">
                        <img class="img-fluid" src="assets/img/mac-pro.png" alt="mac-pro">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End CTA Area -->

<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Works</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Works</span>
                </div>
            </div>
        </div>

        <div id="filters" class="filter-menu text-center roxy-projects-active">
            <ul>
                <li class="active" data-filter="*">All</li>
                <li class="" data-filter=".web">Web</li>
                <li class="" data-filter=".mobile">Mobile</li>
                <li class="" data-filter=".ec">E-commerce</li>
                <li class="" data-filter=".python">Python</li>
                <li class="" data-filter=".django">Django</li>
            </ul>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web">
                <div class="work-details">
                    <img src="assets/img/work-1.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item mobile django python">
                <div class="work-details">
                    <img src="assets/img/work-2.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item ec web">
                <div class="work-details">
                    <img src="assets/img/work-3.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item python mobile">
                <div class="work-details">
                    <img src="assets/img/work-4.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item django ec">
                <div class="work-details">
                    <img src="assets/img/work-5.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item web python">
                <div class="work-details">
                    <img src="assets/img/work-6.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Works Area -->

<!-- Start About Us Area -->
<section id="about" class="about-us ptb-100">
    <div class="container">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>About Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                <span class="section-title-bg">About</span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-medal"></i>
                    <h3>Backgrounds</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-sitemap"></i>
                    <h3>Our Approach</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-thermometer"></i>
                    <h3>Methodology</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-star"></i>
                    <h3>Our Rich Experience</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-funnel-dollar"></i>
                    <h3>Value for Money</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-users-cog"></i>
                    <h3>Flexible Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Us Area -->

<!-- Start Our team Area -->
<section id="team" class="our-team ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Team</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Team</span>
                </div>
            </div>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="team-box">
                <img src="assets/img/team-one.jpg" alt="Image Description">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Colin Firth</h3>
                        <span class="post">Web Developer</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="#"></a></li>
                            <li><a class="fab fa-twitter" href="#"></a></li>
                            <li><a class="fab fa-linkedin-in" href="#"></a></li>
                            <li><a class="fab fa-instagram" href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-two.jpg" alt="Image Description">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Michael Sheen</h3>
                        <span class="post">Web Developer</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="#"></a></li>
                            <li><a class="fab fa-twitter" href="#"></a></li>
                            <li><a class="fab fa-linkedin-in" href="#"></a></li>
                            <li><a class="fab fa-instagram" href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-three.jpg" alt="Image Description">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Tom Hardy</h3>
                        <span class="post">Web Developer</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="#"></a></li>
                            <li><a class="fab fa-twitter" href="#"></a></li>
                            <li><a class="fab fa-linkedin-in" href="#"></a></li>
                            <li><a class="fab fa-instagram" href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-two.jpg" alt="Image Description">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Idris Elba</h3>
                        <span class="post">Web Developer</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="#"></a></li>
                            <li><a class="fab fa-twitter" href="#"></a></li>
                            <li><a class="fab fa-linkedin-in" href="#"></a></li>
                            <li><a class="fab fa-instagram" href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our team Area -->

<!-- Start Our blog Area -->
<section id="blog" class="our-blog ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Blog</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Blog</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-one.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            28 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot marketing trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-two.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            25 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot Themes trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-three.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            15 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot Template trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-four.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            10 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">5 hot plugins trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 all-post">
                <div class="center-wrap text-center">
                    <div class="button">
                        <a routerLink="blog">View All Post <i class="fas fa-long-arrow-alt-right"></i></a>
                        <div class="mask"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our blog Area -->

<!-- Start Video Area -->
<section class="video-area video-bg">
    <div class="diplay-table">
        <div class="display-table-cell">
            <div class="video-inner-content">
                <div class="button__holder" data-parallax='{"z": -400}'>
                    <a href="https://vimeo.com/90691438" class="plus popup-youtube"></a>
                </div>
                <h2 data-parallax='{"z": -400}'>Watch Video</h2>
                <p data-parallax='{"z": -200}'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor</p>
            </div>
        </div>
    </div>
</section>
<!-- End Video Area -->

<!-- Start Our Pricing Area -->
<section id="pricing" class="our-pricing ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Pricing</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Pricing</span>
                </div>
            </div>
        </div>

        <div class="tabs">
            <div class="tabs-button">
                <div class="active tabs-filter-button">Monthly</div>
                <div class="tabs-filter-button">Yearly</div>
            </div>

            <div class="tab-content">
                <div class="content active">
                    <div class="pricing-container margin-top-60">
                        <!-- Plan #1 -->
                        <div class="plan">
                            <div class="plan-price">
                                <h3>Basic</h3>
                                <span class="value">Free</span>
                                <span class="period">Free of charge one standard listing active for 30 days</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>One Listing</li>
                                    <li>30 Days Availability</li>
                                    <li>Standard Listing</li>
                                    <li>Limited Support</li>
                                </ul>
                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Plan #2 -->
                        <div class="plan featured">
                            <div class="listing-badges">
                                <span class="featured">Featured</span>
                            </div>
                            <div class="plan-price">
                                <h3>Extended</h3>
                                <span class="value">$10.99</span>
                                <span class="period">One time fee for one listing, highlighted in the search results</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>One Time Fee</li>
                                    <li>One Listing</li>
                                    <li>90 Days Availability</li>
                                    <li>Featured In Search Results</li>
                                    <li>Featured In Search Results</li>
                                    <li>24/7 Support</li>
                                </ul>

                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Plan #3 -->
                        <div class="plan">
                            <div class="plan-price">
                                <h3>Professional</h3>
                                <span class="value">$19.99</span>
                                <span class="period">Monthly subscription for unlimited listings and availability</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>Unlimited Listings</li>
                                    <li>Unlimited Availability</li>
                                    <li>Featured In Search Results</li>
                                    <li>24/7 Support</li>
                                </ul>
                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="pricing-container margin-top-60">
                        <!-- Plan #1 -->
                        <div class="plan">
                            <div class="plan-price">
                                <h3>Basic</h3>
                                <span class="value">Free</span>
                                <span class="period">Free of charge one standard listing active for 30 days</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>One Listing</li>
                                    <li>30 Days Availability</li>
                                    <li>Standard Listing</li>
                                    <li>Limited Support</li>
                                </ul>

                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Plan #2 -->
                        <div class="plan featured">
                            <div class="listing-badges">
                                <span class="featured">Featured</span>
                            </div>

                            <div class="plan-price">
                                <h3>Extended</h3>
                                <span class="value">$19.99</span>
                                <span class="period">One time fee for one listing, highlighted in the search results</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>One Time Fee</li>
                                    <li>One Listing</li>
                                    <li>90 Days Availability</li>
                                    <li>Featured In Search Results</li>
                                    <li>Featured In Search Results</li>
                                    <li>24/7 Support</li>
                                </ul>

                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Plan #3 -->
                        <div class="plan">
                            <div class="plan-price">
                                <h3>Professional</h3>
                                <span class="value">$39.99</span>
                                <span class="period">Monthly subscription for unlimited listings and availability</span>
                            </div>

                            <div class="plan-features">
                                <ul>
                                    <li>Unlimited Listings</li>
                                    <li>Unlimited Availability</li>
                                    <li>Featured In Search Results</li>
                                    <li>24/7 Support</li>
                                </ul>

                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Pricing Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Some Fun Facts</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Fun Facts</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-035-coin-1"></i>
                    </div>
                    <p>Clients Worked With</p>
                    <h2 class="counter">1548</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-020-handshake"></i>
                    </div>
                    <p>Completed Projects</p>
                    <h2 class="counter">895</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-005-trophy"></i>
                    </div>
                    <p>Winning Awards</p>
                    <h2 class="counter">56</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Testimonials Area -->
<section class="testimonials ptb-100">
    <div class="container">
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="single-testimonial-item text-center">
                <i class="fa fa-quote-left"></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                <div class="client-profile">
                    <img src="assets/img/client-one.png" alt="client-one">
                </div>
                <div class="client-info">
                    <h3>Jason Statham</h3>
                    <span>Founder & Director</span>
                </div>
            </div>

            <div class="single-testimonial-item text-center">
                <i class="fa fa-quote-left"></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                <div class="client-profile">
                    <img src="assets/img/client-two.png" alt="client-two">
                </div>
                <div class="client-info">
                    <h3>Tom Hardy</h3>
                    <span>Founder & Director</span>
                </div>
            </div>

            <div class="single-testimonial-item text-center">
                <i class="fa fa-quote-left"></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                <div class="client-profile">
                    <img src="assets/img/client-three.png" alt="client-one">
                </div>
                <div class="client-info">
                    <h3>Idris Elba</h3>
                    <span>Founder & Director</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->

<!-- Start FAQ Area -->
<section class="faq ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Frequntly Ask & Questions</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">FAQ</span>
                </div>
            </div>
        </div>

        <div class="faq-content">
            <div class="row">
                <div class="col-lg-4">
                    <a href="#">
                        <div class="content-box color-effect-1">
                            <h3>One More Question?</h3>
                            <div class="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                <div class="box-icon"><i class="far fa-share-square"></i></div>
                            </div>
                            <p>If you have more questions, send us a message and we will answer you as soon as possible.</p>
                        </div>
                    </a>
                </div>

                <div class="col-lg-8">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How do I Purchage an item?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse show-div show" role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros.</p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How to download your items?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        View & download invoices?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingThree">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFour">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is Item Support?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFour" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingFour">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFive">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How to contact an author?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFive" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingFive">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Our Partners Area -->
<section class="our-partners ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Partners</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Partners</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="partners-slides owl-carousel owl-theme">
                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img1.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img2.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img3.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img4.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img5.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img6.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img7.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img8.png" alt="Image"></a>
                </div>

                <div class="single-partner-logo">
                    <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img9.png" alt="Image"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Partners Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Contact with Us</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Contact</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="address-area">
                    <div class="contact-info">
                        <i class="fas fa-map-marker-alt"></i>
                        <h4>Address</h4>
                        <p>2750 Quadra Street Victoria, Canada.</p>
                    </div>
                    <div class="contact-info">
                        <i class="fas fa-envelope"></i>
                        <h4>Email</h4>
                        <p>myasi@gmail.com</p>
                    </div>
                    <div class="contact-info">
                        <i class="fas fa-phone-volume"></i>
                        <h4>Phone</h4>
                        <p>+1-325-555-0156</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="contact-form">
                    <form id="contatc_form" method="POST" action="assets/include/sendmail.php" name="myform">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_name" name="name" placeholder="Name" required="" data-parsley-minlength="4">
                                </div>
                            </div>
                            
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" id="contact_email" name="email" placeholder="Email" required="">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_subject" name="sub" placeholder="Subject" required="" data-parsley-minlength="4">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_phone" name="phone" placeholder="Phone" required="">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="contact_message" name="message" rows="7" placeholder="Message" required="" data-parsley-trigger="keyup" data-parsley-minlength="10" data-parsley-maxlength="100" data-parsley-minlength-message="Come on! You need to enter at least a 10 character comment.." data-parsley-validation-threshold="10"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div id="contact_send_status"></div>
                            </div>
                        </div>

                        <div class="center-wrap text-center">
                            <div class="button">
                                <button type="submit">Send Message</button>
                                <div class="mask"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->