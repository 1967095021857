import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-two',
  templateUrl: './work-two.component.html',
  styleUrls: ['./work-two.component.css']
})
export class WorkTwoComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;

    this.album.push({ 'src': 'assets/img/work/main-page/5.jpg', 'caption': 'Toyota Lanka Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-5.jpg' });//1
    this.album.push({ 'src': 'assets/img/work/toyota/2.jpg', 'caption': 'Toyota Lanka Ad Campaign', 'thumb': 'assets/img/work/toyota/thumbs/work-2.jpg' });//2
    this.album.push({ 'src': 'assets/img/work/toyota/3.jpg', 'caption': 'Toyota Lanka Ad Campaign', 'thumb': 'assets/img/work/toyota/thumbs/work-3.jpg' });//3
    this.album.push({ 'src': 'assets/img/work/toyota/4.jpg', 'caption': 'Toyota Lanka Ad Campaign', 'thumb': 'assets/img/work/toyota/thumbs/work-4.jpg' });//4
    this.album.push({ 'src': 'assets/img/work/toyota/5.jpg', 'caption': 'Toyota Lanka Ad Campaign', 'thumb': 'assets/img/work/toyota/thumbs/work-5.jpg' });//5



  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
