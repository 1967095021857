<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Hero of The Heart Campaign </h2>
                    <p>Hero of the Heart Campaign was an Internal communication Campaign that set new milestones in the
                        history of Seylan Bank. The campaign has galvanised staff members at the bank to be more active
                        and interactive in their job roles. Each of them were given individual targets, including the
                        CEO. These targets were achieved beyond everyone's belief. This campaign was envisaged as a
                        Motivational Campaign where individual skills and teamwork were recognized and highly valued.
                        The 'Hero of the Heart' campaign has had a significant impact on increasing employee value at
                        all levels. It also provided several possibilities for professional bankers to contribute their
                        best efforts to improving the Bank's image and performance.
                    </p>
                    <p>
                        Designs Dee have executed the entire creative campaign of this project. Our creative work
                        included graphic designs, videos, internal EDMs, layout designs and Hoardings which were placed
                        in the front office of the Bank's headquarters. All the social media compatible content from the
                        lot were also posted in the social media pages of the bank. We are proud and privileged to have
                        been part of this outstanding movement.
                    </p>
                    <span class="section-title-bg">SeylanBank </span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)" *ngIf="(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>

                <!-- <div class="work-details popup-youtube" href="https://www.youtube.com/watch?v=2oJmusYKi48"  *ngIf="!(image.type == 'image')"> -->
                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">

                    <!-- <img [src]="image.thumb" [alt]="image.caption"> -->
                    <div class="videos">
                        <a href="#" class="video">
                            <span></span>
                            <img [src]="image.thumb" [alt]="image.caption">
                        </a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->