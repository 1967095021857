<a href="#top" class="scrolltop">Top</a>

<!-- Start Footer Area -->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <p class="copyright">&copy; 2021 All Rights Reserved.</p>
            </div>

            <div class="col-md-7">
                <div class="social-icons bottom">
                    <ul class="list-inline">
                        <li>Follow Us On: </li>
                        <li><a href="https://www.facebook.com/Designs-Dee-Advertising-Solutions-112513937135198" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript:void(0)" ><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/80310393/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/designsdee777/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->