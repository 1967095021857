<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Mega Rewards Campaign</h2>
                    <p>Seylan Bank initiated a brand-new rewards scheme aimed at a number of target groups, as well as
                        significant events in their lives. We were successful in handling their entire external EDM
                        campaign focusing on children, A/L students, graduates, elders, youngsters, business people,
                        about-to-be married and newly married couples, new-born children, those awaiting surgery and
                        people touring overseas, as they reached important milestones and events.
                    </p>
                    <!-- <p>
                        When working together with Seylan bank, Designs Dee had to consider many vital factors so as to
                        provide exactly what the bank’s management wanted. The colors, fonts, and other creative
                        parameters were selected adhering to the bank’s default theme. As a result, we were able to
                        protect and strengthen the bank's brand image further as demanded by the bank. We also made
                        every effort to provide the finest possible client-oriented service targeting the bank's various
                        business components, just as we did in the creative design process.
                    </p> -->
                    <span class="section-title-bg">SEYLANBANK</span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">

                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->