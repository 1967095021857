import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { OwlModule } from 'ngx-owl-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { BlogComponent } from './demos/blog/blog.component';
import { BlogDetailsComponent } from './demos/blog-details/blog-details.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WorksComponent } from './works/works.component';
import { LightboxModule } from 'ngx-lightbox';
import { WorkOneComponent } from './work-pages/work-one/work-one.component';
import { WorkTwoComponent } from './work-pages/work-two/work-two.component';
import { WorkThreeComponent } from './work-pages/work-three/work-three.component';
import { WorkFourComponent } from './work-pages/work-four/work-four.component';
import { WorkFiveComponent } from './work-pages/work-five/work-five.component';
import { WorkSixComponent } from './work-pages/work-six/work-six.component';
import { WorkSevenComponent } from './work-pages/work-seven/work-seven.component';
import { WorkEightComponent } from './work-pages/work-eight/work-eight.component';
import { WorkNineComponent } from './work-pages/work-nine/work-nine.component';
import { WorkTenComponent } from './work-pages/work-ten/work-ten.component';
import { WorkElevenComponent } from './work-pages/work-eleven/work-eleven.component';
import { WorkTwelveComponent } from './work-pages/work-twelve/work-twelve.component';
import { WorkThirteenComponent } from './work-pages/work-thirteen/work-thirteen.component';
import { WorkFourteenComponent } from './work-pages/work-fourteen/work-fourteen.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DemoTwoComponent,
    DemoThreeComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactUsComponent,
    AboutUsComponent,
    WorksComponent,
    WorkOneComponent,
    WorkTwoComponent,
    WorkThreeComponent,
    WorkFourComponent,
    WorkFiveComponent,
    WorkSixComponent,
    WorkSevenComponent,
    WorkEightComponent,
    WorkNineComponent,
    WorkTenComponent,
    WorkElevenComponent,
    WorkTwelveComponent,
    WorkThirteenComponent,
    WorkFourteenComponent,
    WhoWeAreComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule,
    LightboxModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
