<!-- Start About Us Area -->
<section id="about" class="about-us ptb-100">
    <div class="container">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>About Us</h2>
                <p>
                    Designs Dee Advertising Solutions combines razor-sharp strategy, combined with breath-taking
                    creativity that is based on an understanding of real-world issues and human motivation across
                    linguistic, social and cultural barriers.
                </p>
                <p>
                    We bring you the very best efforts of skilful, strategic, creative, young, and out-of-the-box
                    thinking professionals who work as part of an extended family. We have also enthusiastically
                    embraced Digital marketing – absorbing the latest technologies and processes from this exciting
                    field. All these excellent traits have collectively contributed towards forging the company’s
                    reputation over the years.
                </p>
                <span class="section-title-bg">About</span>
            </div>
        </div>

        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>Our Vision</h2>
                <p>
                    To be the most creative and professional advertising agency offering invaluable services to clients'
                    business success.
                </p>
                <!-- <span class="section-title-bg">Vision </span> -->
            </div>
        </div>

        <div class="container">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Mission</h2>
                    <p>
                        To contribute to clients' business growth through results-oriented, innovative marketing
                        solutions,
                        using best-in-class practices and cutting-edge technology.
                    </p>
                    <!-- <span class="section-title-bg">Mission</span> -->
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <!-- <i class="fas fa-medal"></i> -->
                    <i class="fas fa-users"></i>
                    <h3>Value for Clients</h3>
                    <p>
                        As a fully client-oriented organization, our primary goal is client satisfaction.
                        We take total
                        ownership of providing the very best in creativity that produces an indelible impact in the
                        minds of customers, while achieving the marketing objectives of clients.</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <!-- <i class="fas fa-sitemap"></i> -->
                    <i class="fas fa-handshake"></i>
                    <h3>Integrity &amp; Confidentiality</h3>
                    <p>We only handle a single client in each industry. This helps us in providing our undivided
                        attention to each client, while also affirming the utmost in integrity, trust and
                        confidentiality.
                        As a responsible organization. we have experienced professionals who have been entrusted with
                        this important task.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <!-- <i class="fas fa-thermometer"></i> -->
                    <i class="fas fa-chart-line"></i>
                    <h3>Excellence Service</h3>
                    <p> By constantly benchmarking against peers, competitors and other industries, we are able to raise
                        our service excellence. We also introduce new concepts and processes to our service, while
                        upgrading existing services to meet the emerging technologies and needs of the changing business
                        environment.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <i class="fas fa-glass-cheers"></i>
                    <h3>Customer Satisfraction</h3>
                    <p> We strive to maximise customer satisfaction by constantly seeking feedback on our service and
                        processes across all customer touchpoints. These details are shared with our team on a regular
                        basis, which enable us to offer to meet clients’
                        needs.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <!-- <i class="fas fa-funnel-dollar"></i> -->
                    <i class="fas fa-certificate"></i>
                    <h3>Trustworthy</h3>
                    <p>Producing work that exceeds customer expectations and meeting deadlines are factors we focus on a
                        regular basis. We have strong Company policies which ensure that clients’ ROI remains our number
                        one priority.</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="about-info">
                    <!-- <i class="fas fa-users-cog"></i> -->
                    <i class="far fa-registered"></i>
                    <h3>Creativity</h3>
                    <p>Creativity forms the core function of our business, which helps us produce work that transcends
                        and translates across linguistic as well as socio-cultural boundaries. We have a multi-ethnic
                        and multi-cultural team for this
                        purpose.
                    </p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
    </div>

</section>
<!-- End About Us Area -->
