<!-- Start Homepage Slider -->
<div class="hompage-slides-wrapper">
    <div class="homepage-slides main-slides owl-carousel" data-slider-id="1">
        <div class="single-slider-item slide-bg-1" data-parallax='{"y": -150, "smoothness": 20}'>
            <div class="diplay-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <!-- <div class="col-lg-7" data-parallax='{"y" : -300, "smoothness": 20}'>
                                <span class="hero-text">Clean & Creative</span>
                                <h1>Fully Responsive Design</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="single-slider-item slide-bg-2" data-parallax='{"y": -150, "smoothness": 20}'>
            <div class="diplay-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7" data-parallax='{"y" : -300, "smoothness": 20}'>
                                <span class="hero-text">Unique Features</span>
                                <h1>Fully Responsive Design</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-slider-item slide-bg-3" data-parallax='{"y": -150, "smoothness": 20}'>
            <div class="diplay-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7" data-parallax='{"y" : -300, "smoothness": 20}'>
                                <span class="hero-text">Reliable Support</span>
                                <h1>Fully Responsive Design</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                                <div class="center-wrap">
                                    <div class="button">
                                        <a href="#">get started <i class="fas fa-long-arrow-alt-right"></i></a>
                                        <div class="mask"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <!-- Carousel Thumbs -->
    <!-- <div class="owl-thumbs" data-slider-id="1" data-parallax='{"y": -150, "smoothness": 20}'> -->
    <div class="owl-thumbs" data-parallax='{"y": -150, "smoothness": 20}'>
        <div class="owl-thumb-item">
            <div class="slide-service-item">
                <div class="glyph">
                    <i class="glyph-icon flaticon-excellence"></i>
                </div>
                <h3>Excellence</h3>
                <p>Updating our skills and competencies while upgrading our processes enables us to constantly raise the
                    bar on our performance.</p>
            </div>
        </div>

        <div class="owl-thumb-item">
            <div class="slide-service-item">
                <div class="glyph">
                    <i class="glyph-icon flaticon-enthusiast"></i>
                </div>
                <h3> Passion</h3>
                <p>Our deeply ingrained enthusiasm spurs us on to approach each new project with vigour and creativity
                    to maximise its effectiveness.</p>
            </div>
        </div>

        <div class="owl-thumb-item">
            <div class="slide-service-item">
                <div class="glyph">
                    <i class="glyph-icon flaticon-expert"></i>
                </div>
                <h3>Our Expertise</h3>
                <p>We possess in-depth knowledge on all aspects of our business, from strategy planning and creative
                    conceptualising, to execution across all media.</p>
            </div>
        </div>
    </div>
</div>
<!-- End Homepage Slider -->

<!-- Start Services Area -->
<section  class="services ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Services</h2>
                    <p>Being customer-centric and service-driven at all levels of our organization helps us to respond
                        effectively and immediately, while proactively planning ahead to meet our clients’ strategic
                        needs efficiently.</p>
                    <span class="section-title-bg">Services</span>
                </div>
            </div>
        </div>
        <span id="services"></span>
        <div  class="row">

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-design-process"></i>
                    </div>
                    <h3>Creative Designs</h3>
                    <p>We specialize in creating distinctive designs which appeal to audiences rationally and
                        emotionally, in order to convert them into loyal customers.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-bullhorn"></i>
                    </div>
                    <h3>Social Media Advertising</h3>
                    <p>Combining in-depth analysis of data and metrics with strategic thinking helps us to streamline
                        our efforts to maximise impact and conversion.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-brand"></i>
                    </div>
                    <h3>Creating Brand Presence</h3>
                    <p>Having a 360-degree approach to implementing brand strategies helps us build or reinvigorate
                        brands to achieve enduring stature & success in the market.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-infographic"></i>
                    </div>
                    <h3>Video Content Creation</h3>
                    <p>We use cutting-edge technology fused with strategy-driven creativity, backed by analysis of
                        audience needs, motivations and trends to create video content of unmatched style, substance and
                        appeal.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-script"></i>
                    </div>
                    <h3>Script Writing</h3>
                    <p>We are creating scripts that have
                        We have our own in-house writers as well as professionals specialising in various styles of
                        script-writing. This helps us produce scripts that combine aesthetics with impact cross all
                        media </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 text-center">
                <div class="service-item">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-website"></i>
                    </div>
                    <h3>Website Development</h3>
                    <p>Incorporating exceptional aesthetics with cutting edge technology and design processes, our web
                        development ensures high SEO for the site, together with streamlined processes that meet
                        clients’ objectives and key web metrics.
                    </p>
                </div>
            </div>

         
        </div>


    </div>

</section>
<!-- End Services Area -->

<!-- Start CTA Area -->
<section class="cta-area cta-bg-img">
    <div class="diplay-table">
        <div class="display-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-7 col-lg-7">
                        <div class="cta-info">
                            <h2>Build your business while growing your brand</h2>
                            <p>Your business has a better chance of achieving success and sustainability in the long-run
                                when you grow your brand simultaneously. We have the experience and expertise to help
                                you achieve both at the same time.</p>
                            <!-- <div class="center-wrap">
                                <div class="button">
                                    <a href="#">Purchage Now <i class="fas fa-long-arrow-alt-right"></i></a>
                                    <div class="mask"></div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-5 col-lg-5 cta-img">
                        <img class="img-fluid" src="assets/img/mac-pro.png" alt="mac-pro">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End CTA Area -->


<!-- Start Our team Area -->
<section id="team" class="our-team ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Core Team</h2>
                    <p>We have a team of highly experienced and qualified experts in Creative Design, Video Production,
                        Animation, Digital Marketing, and Public Relations.</p>
                    <span class="section-title-bg">Team</span>
                </div>
            </div>
        </div>

        <div class="team-slides owl-carousel owl-theme">

            <div class="team-box">
                <img src="assets/img/team-four.jpg" alt="Image Description">

                <!-- <div class="box-content" (click)="teamNavigate()" onclick="javascript:window.location.href='/who-we-are'" > -->
                <div class="box-content" (click)="teamNavigate('')">
                    <div class="box-inner-content">
                        <h3 class="title"> <a href="/who-we-are" style="color: #fe5619"> Dammi Adikaree</a></h3>
                        <span class="post">CEO</span>
                        <ul class="icon qq">
                            <li><a class="fab fa-facebook-f" href="https://www.facebook.com/dhammi.adhikaree" ></a></li>
                            <li><a class="fab fa-twitter" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-linkedin-in"
                                    href="https://www.linkedin.com/in/dammi-adikaree-30843385/" ></a>
                            </li>
                            <li><a class="fab fa-instagram" href="https://instagram.com/dammikakumariadikaree/" ></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-one.jpg" alt="Image Description">

                <div class="box-content" (click)="teamNavigate('finance-director')">
                    <div class="box-inner-content">
                        <h3 class="title"> <a href="/who-we-are#finance-director" style="color: #fe5619"> Jerome
                                Moses</a></h3>
                        <span class="post ">Finance Director</span>

                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-twitter" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-linkedin-in" href="https://www.linkedin.com/in/jerome-moses-362b6943/"
                                    target="_blank"></a></li>
                            <li><a class="fab fa-instagram" href="javascript:void(0)"></a></li>
                        </ul>

                        <!-- <a href="/who-we-are#director"> More Info</a> -->
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-two.jpg" alt="Image Description">

                <div class="box-content" (click)="teamNavigate('art-director')">
                    <div class="box-inner-content">
                        <h3 class="title"> <a href="/who-we-are#art-director" style="color: #fe5619"> Kanthi
                                Samarakoon</a></h3>
                        <span class="post title-color">Art Director</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-twitter" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-linkedin-in" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-instagram" href="javascript:void(0)"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="team-box">
                <img src="assets/img/team-three.jpg" alt="Image Description">

                <div class="box-content" (click)="teamNavigate('digital-marketing')">
                    <div class="box-inner-content">
                        <h3 class="title"> <a href="/who-we-are#digital-marketing" style="color: #fe5619"> Nishantha
                                Kumara</a></h3>
                        <span class="post title-color">Digital Marketing</span>
                        <ul class="icon">
                            <li><a class="fab fa-facebook-f" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-twitter" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-linkedin-in" href="javascript:void(0)"></a></li>
                            <li><a class="fab fa-instagram" href="javascript:void(0)"></a></li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>
<!-- End Our team Area -->

<!-- Start Our blog Area -->
<!-- <section id="blog" class="our-blog ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Blog</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">Blog</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-one.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            28 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot marketing trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-two.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            25 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot Themes trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-three.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            15 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">10 hot Template trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <div class="blog-item">
                    <a class="blog-img" routerLink="blog-details">
                        <img src="assets/img/blog-four.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            10 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a routerLink="blog-details">5 hot plugins trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 all-post">
                <div class="center-wrap text-center">
                    <div class="button">
                        <a routerLink="blog">View All Post <i class="fas fa-long-arrow-alt-right"></i></a>
                        <div class="mask"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Our blog Area -->

<!-- Start Video Area -->
<section class="video-area video-bg">
    <div class="diplay-table">
        <div class="display-table-cell">
            <div class="video-inner-content">
                <div class="button__holder" data-parallax='{"z": -400}'>
                    <a href="https://www.youtube.com/watch?v=su-s2C7P0rM" class="plus popup-youtube"></a>
                </div>
                <h2 data-parallax='{"z": -400}'>Hero of the Heart Campaign</h2>
                <p data-parallax='{"z": -200}'>' Hero of the Heart 'Campaign was an Internal communication Campaign that
                    has set new milestones in the history of Seylan Bank.It has galvanised staff members at the Bank
                    into action. This campaign was envisaged as a Motivational Campaign where individual skills and
                    teamwork were recognized and highly valued.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Video Area -->

<!-- Start Our Pricing Area -->


<!-- End Our Pricing Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our Journey</h2>
                    <p>Designs Dee is a fully client-oriented advertising agency. Our journey has been one of continuous
                        success, overcoming constant challenges and facing a multitude of obstacles, while forging ahead
                        in our goal of building our clients’ brand and ensuring the highest ROI for their marketing
                        efforts. We have achieved this by constantly upgrading our product and service offering, while
                        maintaining the highest standards in integrity, professionalism and ethical practices with our
                        partners and suppliers.</p>
                    <span class="section-title-bg">Journey</span>
                </div>
            </div>
        </div>
        <!-- 
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-trust"></i>
                    </div>
                    <p>Client Trust</p>
                    <h2 class="counter">210</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-experience"></i>
                    </div>
                    <p>Experience</p>
                    <h2 class="counter">160</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="count-box text-center">
                    <div class="glyph">
                        <i class="glyph-icon flaticon-trophy"></i>
                    </div>
                    <p>Awards</p>
                    <h2 class="counter">2</h2>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- End Fun Facts Area -->



<!-- Start FAQ Area -->
<!-- <section class="faq ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Frequntly Ask & Questions</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    <span class="section-title-bg">FAQ</span>
                </div>
            </div>
        </div>

        <div class="faq-content">
            <div class="row">
                <div class="col-lg-4">
                    <a href="#">
                        <div class="content-box color-effect-1">
                            <h3>One More Question?</h3>
                            <div class="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                <div class="box-icon"><i class="far fa-share-square"></i></div>
                            </div>
                            <p>If you have more questions, send us a message and we will answer you as soon as possible.</p>
                        </div>
                    </a>
                </div>

                <div class="col-lg-8">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How do I Purchage an item?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse show-div show" role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros.</p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How to download your items?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        View & download invoices?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingThree">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFour">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is Item Support?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFour" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingFour">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFive">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How to contact an author?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFive" class="panel-collapse collapse show-div" role="tabpanel" aria-labelledby="headingFive">
                                <div class="panel-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End FAQ Area -->

<!-- Start Our Partners Area -->
<section class="our-partners ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Our clientele</h2>
                    <p>Our clientele comprises some of the leading names in Sri Lanka’s business landscape as well as
                        small and medium-sized players, who have grown with us. We provide all our clients with the same
                        dedicated attention, while delivering our best creative services using the most effective ways
                        and means.</p>
                    <span class="section-title-bg">clientele</span>
                </div>
            </div>
        </div>

        <div class="partners-slides owl-carousel owl-theme">
            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img1.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img2.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img3.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img4.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img5.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img6.png" alt="Image"></a>
            </div>

            <!-- <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img7.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img8.png" alt="Image"></a>
            </div>

            <div class="single-partner-logo">
                <a class="logo-preview" href="#"><img src="assets/img/partners-logo/img9.png" alt="Image"></a>
            </div> -->
        </div>
    </div>
</section>
<!-- End Our Partners Area -->


<!-- Start Testimonials Area -->
<section class="testimonials ptb-100">
    <div class="container">
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="single-testimonial-item text-center">
                <!-- <i class="fa fa-quote-left"></i> -->
                <p><i class="fa fa-quote-left"></i>Singha Lanka Steel (Pvt) Ltd has been working with Designs Dee
                    Advertising Solutions to fulfill some
                    of the major marketing and advertising requirements of ours. We would like to state that we are more
                    than delighted to have worked with them, mainly because they are professionals and highly skilled in
                    the field of advertising . Last year we managed collaboratively a very successful Social Media
                    Campaign with Designs Dee, which gave high dividends to our company. We recommend Designs Dee to any
                    organization.<i class="fa fa-quote-right"></i></p>

                <!-- <i class="fa fa-quote-right"></i> -->
                <div class="client-profile">
                    <img src="assets/img/client-one.png" alt="client-one">
                </div>
                <div class="client-info">
                    <h3> Chrysantha Fonseka</h3>
                    <span>Director - Singha Lanka Steel (Pvt) Ltd</span>
                </div>
            </div>

            <!-- <div class="single-testimonial-item text-center">
                <i class="fa fa-quote-left"></i>
                <p>Designs Dee has changed the way we have done business, from being just a advertising company, They
                    has helped us grow and even launch new way of thinking. Many thanks to Dammie Adikaree and Designs
                    Dee Team</p>
                <div class="client-profile">
                    <img src="assets/img/client-two.png" alt="client-two">
                </div>
                <div class="client-info">
                    <h3>Sahan perera</h3>
                    <span>Managing Director - Spinner</span>
                </div>
            </div>

            <div class="single-testimonial-item text-center">
                <i class="fa fa-quote-left"></i>
                <p>Designs dee understands our customers like we do. They have been both a sound business consultant and
                    an advertising agency for our company over the years and continues to add value to us in multiple
                    platforms.</p>
                <div class="client-profile">
                    <img src="assets/img/client-three.png" alt="client-one">
                </div>
                <div class="client-info">
                    <h3>Mahesh karunarathne</h3>
                    <span>Managing Director - AMW</span>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Testimonials Area -->