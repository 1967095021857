<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Toyota Lanka Campaign</h2>
                    <p>Toyota Lanka was established in 1995 as a subsidiary of Toyota Tsusho Corporation, Japan, to be
                        the sole distributor for Toyota vehicles and Genuine Spare Parts in the country. We were
                        responsible for the creative design of their press campaigns, EDM and brochures covering their
                        selected brands, focusing on the superior Toyota quality.
                    </p>
                    <span class="section-title-bg">ToyotaAds</span>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm" *ngFor="let image of album; let i=index">
            <img (click)="open(i)" />
        </div> -->
        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)">
                    <img [src]="image.thumb" alt="Toyota Lanka Ad Campaign">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->