<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Bank TV Commercials</h2>
                    <p>Seylan Bank is renowned as one of the top banks in Sri Lanka. Seylan’s branch network includes
                        over 160 branches distributed islandwide. Seylan also boasts an ATM network of 205 units and
                        over 3000 staff members. Seylan Bank has taken steps to create a number of different Brands
                        which are capable of providing a specific service to specific target customer groups. The
                        customer groups include children, A/L students, undergraduates, elders, youngsters, business
                        crowd, small and medium scale enterprises, etc.
                    </p>
                    <!-- <p>
                        When working together with Seylan bank, Designs Dee had to consider many vital factors so as to
                        provide exactly what the bank’s management wanted. The colors, fonts, and other creative
                        parameters were selected adhering to the bank’s default theme. As a result, we were able to
                        protect and strengthen the bank's brand image further as demanded by the bank. We also made
                        every effort to provide the finest possible client-oriented service targeting the bank's various
                        business components, just as we did in the creative design process.
                    </p> -->
                    <span class="section-title-bg">SEYLANBANK</span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">

                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->