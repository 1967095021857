<!-- Start About Us Area -->
<section id="about" class="about-us ptb-100">
    <div class="container">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2> Dammika Adikaree</h2>
                <h5> CEO | B.A Special (UOK), ACIM (UK)</h5>
                <p>
                    As the CEO of Designs Dee Advertising Solutions, Dhammika Adhikaree is responsible for running all
                    facets of the business. She has accomplished many milestones in her long journey as Marketeer. She
                    earned a Mass Communication special degree at the University of Kelaniya holding a second class
                    upper. Also, she has qualified in Chartered Institute of Marketing ( UK) . Dhammi has the proven
                    Executive Management track record in business Development with 15 years experience.
                </p>
                <p>
                    Prior to Designs Dee, Dhammi was handling business development in advertising, bringing in New
                    business to the companies. Some of them are as follows,
                </p>

                <div class="campaigns">

                    <ol>
                        <li> Laugfs Supermarket, Darley Butler & Company, Ford Auto Mobiles, Toyota, Cleanco Lanka (Pvt)
                            Ltd, Melbourne Metal (Pvt) Limited.</li>
                        <li>Laugfs Avurudhu campaign in 2014 – “Lambadiyawe Sahal Mangalya” - concept development , PR ,
                            Press ads , Radio ads.</li>
                        <li>Brand activations for Laugfs Supermarkets.</li>
                        <li>BIC Twin Lady Brand activations & PR campaigns for Darley Butler & Company Limited.</li>
                        <li>Denta Brand activations & PR campaigns for Darley Butler & Company Limited.</li>
                        <li> ‘Lilly’ communication campaign with concept developments, photo shoots, Press
                            advertisements &
                            public Relations for Drley Butler & Company Limited .</li>
                        <li>Toyota Lanka (Pvt) Ltd – brands handled Yaris, Corolla, Avalon, HINO, Rush, Hiace, HINO
                            truck,
                            Coaster, RAV4, Hilux, Fortuner, Land Cruiser, Wigo .</li>
                        <li>Toyota Lanka (Pvt) Ltd – brands handled Yaris, Corolla, Avalon, HINO, Rush, Hiace, HINO
                            truck,
                            Coaster, RAV4, Hilux, Fortuner, Land Cruiser, Wigo .</li>
                    </ol>
                </div>


                <p>
                    She was handling Sales in Cameron Pale & Medina and developed sales for a banking & Finance web
                    page. She brought leading banking & Finance companies to the web page and worked with leading banks
                    and finance companies in Sri Lanka.
                </p>




                <span class="section-title-bg">CEO</span>
            </div>
        </div>

    </div>

    <div id="finance-director"class="container">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>Jerome Moses</h2>
                <h5> Finance Director | FCMA, CGMA, (UK), MBA </h5>
                <p>
                    Jerome is a Chartered Management Accountant and after 10 years of in Senior Managerial positions in
                    leading public quoted companies in multi disciplines he was a director of a US owned multi-national
                    company for over 15 years. Jerome was the Head of Finance, for both the Local and US operations, the
                    Marketing and Production divisions, and was deeply involved in the setting up of production units
                    and the strategic planning of the companies.
                </p>
            </div>
        </div>

    </div>

    <div id="art-director" class="container">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>Kanthi Samarakoon</h2>
                <h5> Art Director | BSc (Hon) (USJ)</h5>

                <p>
                    Kanthi Samarakoon holds a Bachelor of Science degree in Public Administration given by the
                    University of Sri Jayawardenapura. Kanthi always had the mind of a brilliant artist. Kanthi has over
                    20 years of working experience in all kinds of media design and creative solutions in the industry.
                    Her main goal was always to create the finest of ideas and convert these ideas into innovative and
                    practical ways to build up a brand reputation.
                </p>
            </div>
        </div>

    </div>

    <div class="container"id="digital-marketing">
        <div class="col-lg-8 offset-lg-2 text-center">
            <div class="section-title">
                <h2>Nishantha Udaya Kumara</h2>
                <h5>Digital Marketing | M.S.C Physical Science (UOR)</h5>
                <p>
                    Nishantha Udaya Kumara is a Bachelor of Physical Science in University of Mathara. He has gained
                    google analytics individual qualification and google adwards certification backed up with his
                    working experience in the field of Digital Marketing . Nishantha Provides top notch data driven
                    marketing campaigns
                </p>
            </div>
        </div>

    </div>
</section>

<!-- End About Us Area -->