<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Bank Avurudu Campaign</h2>
                    <p>Seylan Bank’s Avurudu social media campaign on Facebook was launched in 2020 . The campaign was
                        based on traditional Avurudu customs. Creative executions in English, Sinhala and Tamil included
                        Kids’ competitions, Sirith-Virith Custom Promotions, using cartoon-based creatives, as well as
                        the design for a Lockdown Diary for kids.</p>
                    <span class="section-title-bg">Seylan&nbsp;Bank</span>
                </div>
            </div>
        </div>


        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">

                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->