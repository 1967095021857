import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-four',
  templateUrl: './work-four.component.html',
  styleUrls: ['./work-four.component.css']
})
export class WorkFourComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/2.jpg', 'caption': 'Seylan Bank Seylan Personal Loan Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-2.jpg',
      'type': 'page', 'link': '/works/seylan-bank-2', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-personal-loan/2.jpg', 'caption': 'Seylan Bank Seylan Personal Loan Campaign', 'thumb': 'assets/img/work/seylan-personal-loan/thumbs/work-2.jpg',
      'type': 'page', 'link': '/works/seylan-bank-2', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-personal-loan/3.jpg', 'caption': 'Seylan Bank Seylan Personal Loan Campaign', 'thumb': 'assets/img/work/seylan-personal-loan/thumbs/work-3.jpg',
      'type': 'page', 'link': '/works/seylan-bank-2', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/seylan-personal-loan/4.jpg', 'caption': 'Seylan Bank Seylan Personal Loan Campaign', 'thumb': 'assets/img/work/seylan-personal-loan/thumbs/work-4.jpg',
      'type': 'page', 'link': '/works/seylan-bank-2', "index": 1
    });




  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
