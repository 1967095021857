<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Spa Ceylon Campaign</h2>
                    <p>'Spa Ceylon', the hallmark of luxury Ayurveda spas, offers Spa treatment menus consisting of over
                        25 carefully designed signature treatments. We were successful in designing an animated
                        promotional video campaign, focusing on branding and awareness for their treatments for Oily
                        Skin and Active Pimples.
                    </p>
                    <span class="section-title-bg">SPACEYLON </span>
                </div>
            </div>
        </div>

        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)" *ngIf="(image.type == 'image')">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>

                <!-- <div class="work-details popup-youtube" href="https://www.youtube.com/watch?v=2oJmusYKi48"  *ngIf="!(image.type == 'image')"> -->
                <div class="work-details popup-youtube" [attr.href]="image.link" *ngIf="!(image.type == 'image')">

                    <!-- <img [src]="image.thumb" [alt]="image.caption"> -->

                    <div class="videos">
                        <a href="#" class="video">
                          <span></span>
                          <img [src]="image.thumb" [alt]="image.caption">
                        </a>
                      </div>

                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->