<!-- Breadcumbs Area -->
<div class="bread-cumbs-area bread-cumbs-bg">
    <div class="diplay-table">
        <div class="display-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <h1>Our Blog</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Breadcumbs Area -->

<!-- Start Our blog Area -->
<section id="blog" class="our-blog main-blog">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-one.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            28 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">10 hot marketing trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-two.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            25 <span class="month">Jan</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">10 hot Themes trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-three.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            15 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">10 hot Template trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-four.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            10 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">5 hot plugins trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-five.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            10 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">5 hot Angular template you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="blog-item">
                    <a class="blog-img" href="blog-details">
                        <img src="assets/img/blog-six.jpg" alt="blog-one">
                    </a>
                    <div class="blog-info">
                        <div class="date-box">
                            10 <span class="month">Dec</span>
                        </div>
                        <div class="title-meta">
                            <h2><a href="blog-details">5 hot Angular trends you need.</a></h2>
                            <div class="post-meta">
                                <ul>
                                    <li><i class="fa fa-user"></i> Posted By: <a href="#">Admin</a></li>
                                    <li><i class="far fa-comment"></i> Comments: <a href="#">11</a></li>
                                    <li><i class="fa fa-tags"></i> Tags: <a href="#">Business</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="post-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.</p>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <div class="col-lg-12 pagination-area text-center">
                <ul class="pagination">
                    <li><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
                    <li class="active"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                </ul>
            </div>
            <!-- End Pagination -->
        </div>
    </div>
</section>
<!-- End Our blog Area -->
