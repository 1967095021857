import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-ten',
  templateUrl: './work-ten.component.html',
  styleUrls: ['./work-ten.component.css']
})
export class WorkTenComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/sinha/1.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-1.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/sinha/2.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-2.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/sinha/3.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-3.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/sinha/4.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-4.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 4
    });

    this.album.push({
      'src': 'assets/img/work/sinha/5.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-5.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/sinha/6.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-6.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/sinha/7.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-7.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 7
    });

    this.album.push({
      'src': 'assets/img/work/sinha/8.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-8.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 8
    });

    this.album.push({
      'src': 'assets/img/work/sinha/9.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-9.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 9
    });

    this.album.push({
      'src': 'assets/img/work/sinha/10.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-10.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 10
    });

    this.album.push({
      'src': 'assets/img/work/sinha/11.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-11.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 11
    });

    this.album.push({
      'src': 'assets/img/work/sinha/12.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-12.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 12
    });

    this.album.push({
      'src': 'assets/img/work/sinha/13.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-13.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 13
    });

    this.album.push({
      'src': 'assets/img/work/sinha/14.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-14.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 14
    });

    this.album.push({
      'src': 'assets/img/work/sinha/15.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-15.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 15
    });

    this.album.push({
      'src': 'assets/img/work/sinha/16.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-16.jpg',
      'type': 'image', 'link': '/works/sinha', "index": 16
    });
    
    this.album.push({
      'src': 'assets/img/work/sinha/17.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-17.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=iMrcrKu4opM', "index": 16
    });
    




    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
