<!-- Start Top Header -->
<!-- <div id="home" class="top-header">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-lg-2">
                <div class="address-bar">
                    <ul class="list-inline">
                        <li><a href="mailto:support@myasi.com"><i class="fa fa-envelope mr-1"></i> support@myasi.com</a></li>
                        <li><a href="tel:+13255550156"><i class="fas fa-phone-volume mr-1"></i> +1-325-555-0156</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="address-bar">
                    <ul class="list-inline">
                        <li><a href="mailto:hello@designsdee.lk"><i class="fa fa-envelope mr-1"></i> hello@designsdee.lk</a></li>
                        <li><a href="tel:+9472 9702960"><i class="fas fa-phone-volume mr-1"></i> +94 72 9702960</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="social-icons">
                    <ul class="list-inline">
                        <li><a href="https://www.facebook.com/Designs-Dee-Advertising-Solutions-112513937135198" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Top Header -->

<!-- Start Main Header -->
<nav class="navbar navbar-expand-md navbar-light">
    <div class="container">
        <a class="navbar-brand logo" href="/">
            <img src="assets/img/logo.png" alt="Logo">
        </a>

        <a class="navbar-brand logo-2" href="/">
            <img class="img-fluid" src="assets/img/logo2.png" alt="Logo">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" href="/">Home</a></li>

                <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/about']">About</a></li> -->
                <li class="nav-item"><a class="nav-link" href='/about'
                        [style.color]="aboutUs == true ? '#fe5619' : 'black'">About</a></li>

                <!-- <li class="nav-item"><a class="nav-link nav-works" href="javascript:void(0)"(click)="navigate()"
                     [style.color]="route == true ? '#fe5619' : 'black'"
                    >Works</a></li> -->

                <li class="nav-item"><a class="nav-link nav-works" href="/works"
                        [style.color]="route == true ? '#fe5619' : 'black'">Works</a></li>

                <li class="nav-item"><a class="nav-link" href="/#services">Services</a></li>

                <!-- <li class="nav-item"><a class="nav-link" href="#team">Team</a></li> -->







                <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>

                <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li> -->

                <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/contact']">Contact</a></li> -->
                <li class="nav-item"><a class="nav-link" href='/contact'
                        [style.color]="contact == true ? '#fe5619' : 'black'">Contact</a></li>
            </ul>
        </div>
        <!--/.nav-collapse -->

        <!-- header-search -->
        <!-- <div class="header-search">
            <button class="search-toggle" data-toggle="modal" data-target="#myModal" ><i class="fa fa-search"></i></button>
        </div> -->
    </div>
</nav>
<!-- End Main Header -->

<!-- Search Modal -->
<div class="search-modal text-center modal fade" id="myModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="header-search-form">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="fa fa-search"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Search Modal -->