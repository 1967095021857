<!-- Start Contact Area -->
<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Contact Us</h2>
                    <p>
                        Whether you’re looking for a creative marketing agency to help you achieve your marketing goals,
                        or need expert support, we're here to help.
                    </p>

                    <p>
                        For all your inquiries please feel free to contact us.
                    </p><span class="section-title-bg">Contact</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="address-area">
                    <div class="contact-info">
                        <i class="fas fa-map-marker-alt"></i>
                        <h4>Address</h4>
                        <p>No 41, Veedi Mawatha , Kandana.</p>
                    </div>
                    <div class="contact-info">
                        <i class="fas fa-envelope"></i>
                        <h4>Email</h4>
                        <p>dhammi@designsdee.lk</p>
                    </div>
                    <div class="contact-info">
                        <i class="fas fa-phone-volume"></i>
                        <h4>Phone</h4>
                        <p>+94 77 1598984</p>
                        <p>+94 11 2226439</p>
                    </div>
                    <div class="contact-info">
                        <i class="fab fa-whatsapp-square"></i>
                        <h4>WhatsApp</h4>
                        <p>+94 72 9702960</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="contact-form">
                    <form #myDiv id="contatc_form" method="POST" name="myform"  (submit)="sendEmail($event)">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_name" name="user_name"
                                        placeholder="Name" required="" data-parsley-minlength="4">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" id="contact_email"name="user_email"
                                        placeholder="Email" required="">
                                </div>
                            </div>

                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_subject" name="sub"
                                        placeholder="Subject" required="" data-parsley-minlength="4">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="contact_phone" name="phone"
                                        placeholder="Phone" required="">
                                </div>
                            </div> -->

                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="contact_message" name="message" rows="7"
                                        placeholder="Message" required="" data-parsley-trigger="keyup"
                                        data-parsley-minlength="10" data-parsley-maxlength="100"
                                        data-parsley-minlength-message="Come on! You need to enter at least a 10 character comment.."
                                        data-parsley-validation-threshold="10"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div id="contact_send_status"></div>
                            </div>
                        </div>

                        <div class="center-wrap text-center">
                            <div class="button">
                                <button type="submit">Send Message</button>
                                <div class="mask"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <!-- Map -->
        <div class="row">
            <div class="col-lg-12 col-md-12">

                <div class="google-maps">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.701773264434!2d79.88848891538306!3d7.044289918842574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f7168e6bb665%3A0xbaa828af9396c1fd!2sVeedi%20Mawatha%2C%20Kandana!5e0!3m2!1sen!2slk!4v1624815765470!5m2!1sen!2slk"
                        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.701773264434!2d79.88848891538306!3d7.044289918842574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f7168e6bb665%3A0xbaa828af9396c1fd!2sVeedi%20Mawatha%2C%20Kandana!5e0!3m2!1sen!2slk!4v1624815765470!5m2!1sen!2slk" width="600" height="450" frameborder="0" style="border:0"></iframe> -->
                </div>
            </div>


        </div>
    </div>
</section>
<!-- End Contact Area -->


<!-- <form class="contact-form" (submit)="sendEmail($event)">
    <label>Name</label>
    <input type="text" name="user_name">
    <label>Email</label>
    <input type="email" name="user_email">
    <label>Message</label>
    <textarea name="message"></textarea>
    <input type="submit" value="Send">
</form> -->
