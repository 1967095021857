import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { BlogComponent } from './demos/blog/blog.component';
import { BlogDetailsComponent } from './demos/blog-details/blog-details.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WorksComponent } from './works/works.component';
import { WorkOneComponent } from './work-pages/work-one/work-one.component';
import { WorkTwoComponent } from './work-pages/work-two/work-two.component';
import { WorkThreeComponent } from './work-pages/work-three/work-three.component';
import { WorkFourComponent } from './work-pages/work-four/work-four.component';
import { WorkFiveComponent } from './work-pages/work-five/work-five.component';
import { WorkSixComponent } from './work-pages/work-six/work-six.component';
import { WorkSevenComponent } from './work-pages/work-seven/work-seven.component';
import { WorkEightComponent } from './work-pages/work-eight/work-eight.component';
import { WorkNineComponent } from './work-pages/work-nine/work-nine.component';
import { WorkTenComponent } from './work-pages/work-ten/work-ten.component';
import { WorkElevenComponent } from './work-pages/work-eleven/work-eleven.component';
import { WorkTwelveComponent } from './work-pages/work-twelve/work-twelve.component';
import { WorkThirteenComponent } from './work-pages/work-thirteen/work-thirteen.component';
import { WorkFourteenComponent } from './work-pages/work-fourteen/work-fourteen.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';

const routes: Routes = [
  { path: '', component: DefaultComponent },
  // { path: 'demo-two', component: DemoTwoComponent },
  // { path: 'demo-three', component: DemoThreeComponent },
  // { path: 'blog', component: BlogComponent },
  // { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  {
    path: 'works', component: WorksComponent,
    //   children: [
    //     { path: 'seylan-bank', component: WorkOneComponent }
    //  ]
  },
  { path: 'works/seylan-bank', component: WorkOneComponent },
  { path: 'works/toyoto-lanka', component: WorkTwoComponent },
  { path: 'works/foot-mania', component: WorkThreeComponent },
  { path: 'works/seylan-bank-2', component: WorkFourComponent },
  { path: 'works/spa-ceylon', component: WorkFiveComponent },
  { path: 'works/amw', component: WorkSixComponent },
  { path: 'works/spinner', component: WorkSevenComponent },
  { path: 'works/seylan-bank-3', component: WorkEightComponent },
  { path: 'works/seylan-bank-4', component: WorkNineComponent },
  { path: 'works/sinha', component: WorkTenComponent },
  { path: 'works/seylan-bank-5', component: WorkElevenComponent },
  { path: 'works/seylan-bank-6', component: WorkTwelveComponent },
  { path: 'works/seylan-bank-7', component: WorkThirteenComponent },
  { path: 'works/seylan-bank-8', component: WorkFourteenComponent },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }