import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import{ init } from 'emailjs-com';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {

  @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {

    // init("user_j1SeHlRcs1FPiiiEJU2cZ");
  }

  ngAfterViewInit(){
    init("user_j1SeHlRcs1FPiiiEJU2cZ");
  }

  public sendEmail(e: Event) {
    console.log(e)
    e.preventDefault();

    emailjs.sendForm('service_0iki88c', 'template_mi2mxa8', e.target as HTMLFormElement, 'user_j1SeHlRcs1FPiiiEJU2cZ')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        this.toastr.success('Our Team will contact you soon', 'Message Sent');
        
      }, (error) => {
        console.log(error.text);
        this.toastr.error('Try Again', 'Message Sending Failed');
      });


    this.myDiv.nativeElement.reset();
  }

}
