import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
      Location, {
          provide: LocationStrategy,
          useClass: PathLocationStrategy
      }
  ]
})
export class AppComponent implements OnInit {
  routerSubscription: any;

  constructor(private router: Router) {
  }

  ngOnInit(){
    this.recallJsFuntions();
  }

  recallJsFuntions() {
      this.routerSubscription = this.router.events
          .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
          .subscribe(event => {
              if (!(event instanceof NavigationEnd)) {
                return;
              }
              window.scrollTo(0, 0)
          });
  }
}
