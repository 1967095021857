import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-seven',
  templateUrl: './work-seven.component.html',
  styleUrls: ['./work-seven.component.css']
})
export class WorkSevenComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/12.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-12.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/spinner/2.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-2.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 2
    });

    this.album.push({
      'src': 'assets/img/work/spinner/3.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-3.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 5
    });

    this.album.push({
      'src': 'assets/img/work/spinner/4.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-4.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 6
    });

    this.album.push({
      'src': 'assets/img/work/spinner/5.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-5.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 4
    });

    this.album.push({
      'src': 'assets/img/work/spinner/6.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-6.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 3
    });

    this.album.push({
      'src': 'assets/img/work/spinner/7.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-7.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=daNqT1dojeQ', "index": 7
    });

    this.album.push({
      'src': 'assets/img/work/spinner/8.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-8.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=yhPcmcEYvyE', "index": 8
    });


    this.album.push({
      'src': 'assets/img/work/spinner/9.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-9.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Gi4eEQozj1A', "index": 9
    });

    this.album.push({
      'src': 'assets/img/work/spinner/10.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-10.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 10
    });


    this.album.push({
      'src': 'assets/img/work/spinner/11.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-11.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 11
    });

    this.album.push({
      'src': 'assets/img/work/spinner/12.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-12.jpg',
      'type': 'image', 'link': '/works/spinner', "index": 12
    });

    this.album.push({
      'src': 'assets/img/work/spinner/13.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-13.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Nw0e8AYc2IY', "index": 13
    });

    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
