import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-thirteen',
  templateUrl: './work-thirteen.component.html',
  styleUrls: ['./work-thirteen.component.css']
})
export class WorkThirteenComponent implements OnInit {

  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/1.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-1.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=FacZnupW1mg', "index": 1
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/2.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-2.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=R8BzBe1pRQM', "index": 2
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/3.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-3.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Y17lsdmhoeg', "index": 3
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/4.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-4.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=pKWSaOLcp3k', "index": 4
    });

    

    //ascending
    this.album = this.album.sort((a, b) => a.index - b.index);

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

}
