import { Component, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-work-five',
  templateUrl: './work-five.component.html',
  styleUrls: ['./work-five.component.css']
})
export class WorkFiveComponent implements OnInit {


  ngOnInit(): void {
  }
  album: any = [];
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {

    // _lightboxConfig.fadeDuration = 1;


    this.album.push({
      'src': 'assets/img/work/main-page/10.jpg', 'caption': 'Spa Ceylon Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-10.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=-jPAuAWeOiQ', "index": 1
    });

    this.album.push({
      'src': 'assets/img/work/spa-ceylon/2.jpg', 'caption': 'Spa Ceylon Campaign', 'thumb': 'assets/img/work/spa-ceylon/thumbs/work-2.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=1zXoo35-xqw', "index": 2
    });

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }
}
