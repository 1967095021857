import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  constructor(private router: Router , private location:Location) {
    console.log("hello")
  }

  ngOnInit() {
  }

  teamNavigate(page) {

    // console.log('Clicked')
    window.location.href='/who-we-are'+'#'+page
    // this.router.navigate(['who-we-are'],{skipLocationChange:true});

    // this.router.navigate(['/', 'who-we-are']);


  }
}
