<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Seylan Bank Personal Loan Campaign</h2>
                    <p>Seylan Bank is one of Sri Lanka’s leading banks with a network that includes over 160 branches
                        and more than 200 ATMs. We successfully launched the EDM campaigns for internal staff motivation
                        and external specific target segments. This campaign highlighted the special benefits offered by
                        the Seylan Personal Loan scheme</p>
                    <span class="section-title-bg">SeylanBank </span>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm" *ngFor="let image of album; let i=index">
            <img (click)="open(i)" />
        </div> -->
        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" *ngFor="let image of album; let i=index">
                <div class="work-details" (click)="open(i)">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <!-- <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Our Works Area -->


<!-- <a href="https://www.youtube.com/watch?v=2oJmusYKi48" class="plus popup-youtube"></a> -->