import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }
  route = false;
  aboutUs = false;
  contact = false;
  ngOnInit() {

    this.router.events.subscribe((val) => {

      if (this.router.url == '/works') {

        this.route = true;
      } else {

        this.route = false;
      }

      if (this.router.url == '/about') {

        this.aboutUs = true;
      } else {

        this.aboutUs = false;
      }

      if (this.router.url == '/contact') {

        this.contact = true;
      } else {

        this.contact = false;
      }


      // console.log(this.router.url)
    })
  }

  navigate() {

    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //     this.router.navigate(['works']);
    // }); 

    // this.router.navigate(['works'])
    // }

    this.router.navigate(['works'])
      .then(() => {
        window.location.reload();
      });

  }

  // pageNavigate(page) {

  //   setTimeout( () => { /*Your Code*/ 
  //      console.log('Clicked')
  //     window.location.href='/'+'#'+page
  //   }, 120 );

 

  // }




}
